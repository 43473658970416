import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
	CButton,
	CForm,
	CFormInput,
	CFormSelect,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CSpinner,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CImage,
} from '@coreui/react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import { Konfirm } from '../helpers/onConfirm'
import { 
	UFunc,
	cAngkaTgl,
	cBulanPendek
} from "../helpers/functions";
import {
	MyButton,
	MyDialoglookup,
} from '../components/index'
import useSatusehat from '../helpers/useSatusehat'

const pjson 			= require('../../package.json')
const MyCardformpasien 	= props => {
	const { 
		pasienID,
		showButton,
		simpanKlik,
		setLoading,
		prosesExpired,
		showToast,
		setSessionaktif,
		...rest 
	} = props;

	const uDispatch 			= useDispatch();
	const { loadToken } 		= useSatusehat(props);
	const [uShowbutton,setShowbutton]	= React.useState(showButton);

	const uBahasaObj	= useSelector(state => state.listBahasa);
	const uSystemsetting= useSelector(state => state.gSystemsetting)
	const uTokenObj		= JSON.parse(localStorage.getItem("token")||"{}");
	const [uEfekview,setEfekview] 			= React.useState(false);
	const uTokensatusehatObj		= JSON.parse(localStorage.getItem("tokensatusehat")||"{}");

	//const [uSettingdefault,setSettingdefault] = React.useState({});
	const [uThnlahirArr,setThnlahirArr] 	= React.useState([]);
	const [uPasienloadObj,setPasienloadObj] = React.useState({});
	const [uIsDlgloading,setDlgloading]		= React.useState(false);
	const [uApimsg,setApimsg]  				= React.useState(null);
	const [uPekerjaanArr,setPekerjaanArr]	= React.useState([]);
	const [uAsuransiArr,setAsuransiArr]		= React.useState([]);

	const [uFormfieldObj,setFormfieldObj]	= React.useState({});
	/*const [uFormfieldObj.nama_lengkap_pasien,uFormfieldObj.nama_lengkap_pasien = ]= React.useState("");
	const [uFormfieldObj.nama_panggilan_pasien,uFormfieldObj.nama_panggilan_pasien = ] 	= React.useState("");
	const [uFormfieldObj.no_rm,uFormfieldObj.no_rm = ] 			= React.useState("0");
	const [uFormfieldObj.sex_pasien,uFormfieldObj.sex_pasien = ] 				= React.useState("L");
	const [uFormfieldObj.tempat_lahir,uFormfieldObj.tempat_lahir = ]= React.useState("");
	const [uFormfieldObj.tgl,uFormfieldObj.tgl = ] 				= React.useState("0");
	const [uFormfieldObj.bln,uFormfieldObj.bln = ] 				= React.useState("0");
	const [uFormfieldObj.thn,uFormfieldObj.thn = ] 				= React.useState("0");

	const [uFormfieldObj.telpon_pasien,uFormfieldObj.telpon_pasien = ] 		= React.useState("");
	const [uFormfieldObj.alamat_pasien,uFormfieldObj.alamat_pasien = ] 		= React.useState("");
	const [uFormfieldObj.pekerjaan_caption,uFormfieldObj.pekerjaan_caption = ] 	= React.useState("");
	const [uFormfieldObj.pekerjaan_id,uFormfieldObj.pekerjaan_id = ]	= React.useState(0);
	const [uFormfieldObj.alergi,uFormfieldObj.alergi = ] 		= React.useState("");
	const [uFormfieldObj.keterangan,uFormfieldObj.keterangan = ] = React.useState("");
	const [uFormfieldObj.asuransi_caption,uFormfieldObj.asuransi_caption = ] 	= React.useState("");
	const [uFormfieldObj.asuransi_id,uFormfieldObj.asuransi_id = ]	= React.useState(0);
	const [uFormfieldObj.nik,uFormfieldObj.nik = ] 				= React.useState("");
	const [uFormfieldObj.ihs_number,uFormfieldObj.ihs_number = ] 	= React.useState("");//-*/

	//---DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]	= React.useState({});
	//---END DLG_LOOKUP--/

	const hdlKlikpekerjaanlookup = () => {
		uDlglookupObj.data_arr 		= uPekerjaanArr||[];
		uDlglookupObj.inputvalue 	= uFormfieldObj.pekerjaan_caption;
		uDlglookupObj.headers 		= "Pilih: Pekerjaan";
		uDlglookupObj.jenis 		= "PEKERJAAN";
		setDlglookupshow(true);
	}
	const hdlKDownpekerjaan = (_EV) => {
		uFormfieldObj.pekerjaan_id = (0);
		if(_EV.which !== 13) return;

		const vCaptions			= (uFormfieldObj.pekerjaan_caption||"").trim();
		if(vCaptions === "") return;

		const vFilterlookupArr = uPekerjaanArr.filter(vItems=>
			(vItems.caption||"").toUpperCase().includes((vCaptions).toUpperCase()));

		if(vFilterlookupArr.length <= 1) {
			if(vFilterlookupArr.length === 1) {
				uFormfieldObj.pekerjaan_id = (vFilterlookupArr[0].id);
				uFormfieldObj.pekerjaan_caption = (vFilterlookupArr[0].caption);
			}
			return;			
		}

		_EV.stopPropagation();
		_EV.preventDefault();
		hdlKlikpekerjaanlookup();
	}
	const hdlKlikasuransilookup = () => {
		uDlglookupObj.data_arr 		= uAsuransiArr||[];
		uDlglookupObj.inputvalue 	= uFormfieldObj.asuransi_caption;
		uDlglookupObj.headers 		= "Pilih Lookup: ASURANSI";
		uDlglookupObj.jenis 		= "ASURANSI";
		setDlglookupshow(true);
	}
	const hdlKDownasuransi = (_EV) => {
		uFormfieldObj.asuransi_id = (0);
		if(_EV.keyCode !== 13) return;

		const vCaptions			= (uFormfieldObj.asuransi_caption||"").trim();
		if(vCaptions === "") return;

		const vFilterlookupArr = uAsuransiArr.filter(vItems=>
			(vItems.caption||"").toUpperCase().includes((vCaptions).toUpperCase()));

		_EV.stopPropagation();
		_EV.preventDefault();

		if(vFilterlookupArr.length === 1) {
			uFormfieldObj.asuransi_id = (vFilterlookupArr[0].id);
			uFormfieldObj.asuransi_caption = (vFilterlookupArr[0].caption);

			document.getElementById("inppekerjaan") &&
				document.getElementById("inppekerjaan").focus();
			return;
		}
		hdlKlikasuransilookup();
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM 		= parseInt(_IDITEM);
		let vArridx;
		let vElfocus;
		if(uDlglookupObj.jenis === "PEKERJAAN") {
			vArridx 	= UFunc.getObjectIndeks(uPekerjaanArr,_IDITEM);
			uFormfieldObj.pekerjaan_id = (uPekerjaanArr[vArridx].id);
			uFormfieldObj.pekerjaan_caption = (uPekerjaanArr[vArridx].caption||"");
			vElfocus 	= document.getElementById("inpalergi");
		} else if(uDlglookupObj.jenis === "ASURANSI") {
			vArridx 	= UFunc.getObjectIndeks(uAsuransiArr,_IDITEM);
			uFormfieldObj.asuransi_id = (uAsuransiArr[vArridx].id);
			uFormfieldObj.asuransi_caption = (uAsuransiArr[vArridx].caption||"");
			vElfocus 	= document.getElementById("inppekerjaan");
		}
		setDlglookupshow(false);	

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			if(!isMobile) vElfocus && vElfocus.focus()
		},400)
	}

	const hdlKlikSimpan = async() => {
		let vElfocus = document.getElementById("inpnamalengkap");
		if((uFormfieldObj.nama_lengkap_pasien||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("Nama Lengkap Pasien Harus Diisi !"); return;
		}
		vElfocus = document.getElementById("inpsex");
		if((uFormfieldObj.sex_pasien||"").trim() !== "L" && (uFormfieldObj.sex_pasien||"").trim() !== "P") {
			vElfocus && vElfocus.focus();
			showToast("Jenis Kelamin Tidak Valid !"); return;
		}
		vElfocus = document.getElementById("inptelphp");
		if((uFormfieldObj.alamat_pasien||"").trim() === "" && (uFormfieldObj.telpon_pasien||"").trim() === "") {
			vElfocus && vElfocus.focus();
			showToast("Salah Satu Dari Telp/HP atau Alamat Harus Diisi !"); return;
		}
		if((uFormfieldObj.nik||"").trim()!==""
			|| parseInt(uFormfieldObj.thn || "0") > 0
			|| parseInt(uFormfieldObj.bln || "0") > 0
			|| parseInt(uFormfieldObj.tgl || "0") > 0
		) {
			const vThnlahir		= UFunc.leadZero(uFormfieldObj.thn,4).toString();
			const vBlnlahir		= UFunc.leadZero(uFormfieldObj.bln,2).toString();
			const vTgllahir		= UFunc.leadZero(uFormfieldObj.tgl,2).toString();
			const vTanggallahir = vThnlahir+"-"+vBlnlahir+"-"+vTgllahir+
				"";
			const vTanggallahirDT	= UFunc.toDate(vTanggallahir);

			vElfocus = document.getElementById("inplahirtgl");
			if((vTanggallahirDT.getDate()) !== parseInt(vTgllahir)) {
				vElfocus && vElfocus.focus();
				showToast("Tanggal Lahir Tidak Valid !"); return;
			}

			vElfocus = document.getElementById("inplahirbln");
			if((vTanggallahirDT.getMonth()+1) !== parseInt(vBlnlahir)) {
				vElfocus && vElfocus.focus();
				showToast("Bulan Lahir Tidak Valid ! "+(vTanggallahirDT.getMonth()+1)+" "+parseInt(vBlnlahir)); return;
			}
			vElfocus = document.getElementById("inplahirthn");
			if(vTanggallahirDT.getFullYear() !== parseInt(vThnlahir)) {
				vElfocus && vElfocus.focus();
				showToast("Tahun Lahir Tidak Valid !"); return;
			}
		}
		vElfocus = document.getElementById("inpasuransi");
		if((uFormfieldObj.asuransi_caption||"").trim() !== "")
			if(parseInt(uFormfieldObj.asuransi_id) <= 0) {
				vElfocus && vElfocus.focus();
				showToast("Nama Asuransi Harus Dipilih dengan Benar !"); return;
			}

		//--CONFIRM_NIK--/
		if((uFormfieldObj.nik||"").trim() === "") {
			if(await Konfirm(
				"<b>NIK Pasien Belum DITAMBAHKAN</b>.."+
				"<small class='fst-italic'>"+
				"<p>Jika NIK Pasien Tidak ADA, Maka Sistem Tidak Akan Mendapatkan IHS Satusehat.</p>"+
				"<p>Apabila IHS Satusehat Tidak Terdaftar, Data Pemeriksaan Tidak Akan Terkirim ke SERVER Satusehat.</p>"+
				"</small>"+
				"Apakah Anda Tetap Akan Melanjutkan ?"+
				"")) apiProsessimpan();
			return;
		}
		//--END CONFIRM_NIK--/

		//showToast("YES"); return;
		apiProsessimpan();
	}
	const hdlKlikReset = async()=>{
		const vNextfunction = ()=>{
			if(parseInt(pasienID) > 0) {
				apiLoadpasien(pasienID); return;
			}

			initFormawal();
		}

		if(await(Konfirm("Apakah Anda Yakin ?"+
			""))) vNextfunction();
	}

	const initFormawal = () => {
		setFormfieldObj({});
		/*uFormfieldObj.nama_lengkap_pasien = ("");
		uFormfieldObj.nama_panggilan_pasien = ("");
		uFormfieldObj.sex_pasien = ("L");
		uFormfieldObj.tgl = ("0"); uFormfieldObj.bln = ("0"); uFormfieldObj.thn = ("0");

		uFormfieldObj.telpon_pasien = ("");
		uFormfieldObj.alamat_pasien = ("");
		uFormfieldObj.pekerjaan_caption = ("");
		uFormfieldObj.pekerjaan_id = (0);
		uFormfieldObj.asuransi_caption = ("");
		uFormfieldObj.asuransi_id = (0);
		uFormfieldObj.alergi = ("");
		uFormfieldObj.keterangan = ("");//--*/
	}

	const contentMain = () => {
		if(uIsDlgloading) return (
			<div className="d-flex justify-content-center align-items-center h-50">
			<CSpinner color="primary"/>
			</div>
		)
		if(uApimsg) return (UFunc.renderHTML(uApimsg));

		const vTgldaftarDT = uPasienloadObj.tgl_input 
			? UFunc.toDatetime(uPasienloadObj.tgl_input) : new Date();

		return (
		<>
			<CRow className="my-2">
			<CCol md="5" lg="4" className="fw-bolder">Nama Lengkap Pasien</CCol>
			<CCol md="7" lg="8">
				<CFormInput size="sm"
					className="text-capitalize"
					maxLength={100}
					value={uFormfieldObj.nama_lengkap_pasien||""}
					onChange={(e)=>{uFormfieldObj.nama_lengkap_pasien = (e.target.value);setEfekview(!uEfekview);}}
					autoComplete="off"
					id="inpnamalengkap"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol md="5" lg="4">Panggilan</CCol>
			<CCol md="7" lg="5">
				<CFormInput size="sm"
					className="text-capitalize"
					maxLength={30}
					value={uFormfieldObj.nama_panggilan_pasien||""}
					onChange={(e)=>{uFormfieldObj.nama_panggilan_pasien = (e.target.value);setEfekview(!uEfekview);}}
					autoComplete="off"
					id="inppanggilan"/>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			{parseInt(pasienID) > 0 && (
			<CRow className="my-2">
			<CCol md="5" lg="4" className="fw-bolder">No RM</CCol>
			<CCol md="7" lg="5">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					maxLength={3}
					value={uFormfieldObj.no_rm||""}
					onChange={(e)=>{uFormfieldObj.no_rm = (e.target.value);setEfekview(!uEfekview);}}
					onFocus={(e)=>e.target.select()}
					autoComplete="off"
					style={{maxWidth:60,width:"auto"}}
					id="inpnorm"/>
				<span className="mx-1">-</span>
				<CInputGroupText className="py-0">{UFunc.leadZero(vTgldaftarDT.getMonth()+1)}</CInputGroupText>
				<span className="mx-1">-</span>
				<CInputGroupText className="py-0">{vTgldaftarDT.getFullYear()}</CInputGroupText>
				</CInputGroup>
			</CCol>
			</CRow>
			)}

			<CRow className="my-2">
			<CCol md="5" lg="4" className="fw-bolder">NIK</CCol>
			<CCol md="7" lg="5">
				<CFormInput size="sm"
					className="text-uppercase"
					maxLength={50}
					value={uFormfieldObj.nik||""}
					onChange={(e)=>{uFormfieldObj.nik = (e.target.value);setEfekview(!uEfekview);}}
					autoComplete="off"
					id="inpnik"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol md="5" lg="4" className="">IHS Number</CCol>
			<CCol md="7" lg="5">
				<CFormInput size="sm"
					className="text-uppercase"
					maxLength={30}
					readOnly={true}
					value={uFormfieldObj.ihs_number||""	}
					onChange={(e)=>{uFormfieldObj.ihs_number=(e.target.value);setEfekview(!uEfekview)}}
					autoComplete="off"
					id="inpihsnumber"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol md="5" lg="4">Tempat, Tgl Lahir</CCol>
			<CCol md="7" lg="8" className="d-md-flex align-items-start">
				<CInputGroup size="sm" 
					style={{maxWidth:225}}
					className="">
				<CFormInput size="sm"
					className="text-capitalize mb-2"
					maxLength={100}
					value={uFormfieldObj.tempat_lahir || ""}
					onChange={(e)=>{uFormfieldObj.tempat_lahir = (e.target.value);setEfekview(!uEfekview);}}
					autoComplete="off"
					id="inptempatlahir"/>
				<div className="mx-1">,</div>
				</CInputGroup>

				<CInputGroup size="sm" className="">
				<CFormSelect size="sm"
					className="mb-2"
					style={{maxWidth:80,width:"auto"}}
					value={uFormfieldObj.tgl}
					onChange={(e)=>{uFormfieldObj.tgl = (e.target.value);setEfekview(!uEfekview);}}
					id="inplahirtgl">
					<option>-TGL-</option>
					{(cAngkaTgl||[]).map((vItems,vKeys)=>{
						return (<option key={vKeys} value={UFunc.leadZero(vItems)}>{UFunc.leadZero(vItems)}</option>)
					})}
				</CFormSelect>
				<span className="mx-1">/</span>
				<CFormSelect size="sm"
					className="mb-2 text-uppercase"
					style={{maxWidth:80,width:"auto"}}
					value={uFormfieldObj.bln}
					onChange={(e)=>{uFormfieldObj.bln = (e.target.value);setEfekview(!uEfekview);}}
					id="inplahirbln">
					<option>-BLN-</option>
					{(cBulanPendek||[]).map((vItems,vKeys)=>{
						return (<option className="text-uppercase" key={vKeys} value={UFunc.leadZero(vKeys+1)}>{vItems}</option>)
					})}
				</CFormSelect>
				<span className="mx-1">/</span>
				<CFormSelect size="sm"
					className="mb-2"
					style={{maxWidth:95,width:"auto"}}
					value={uFormfieldObj.thn}
					onChange={(e)=>{uFormfieldObj.thn = (e.target.value);setEfekview(!uEfekview);}}
					id="inplahirthn">
					<option>-THN-</option>
					{(uThnlahirArr||[]).map((vItems,vKeys)=>{
						return (<option key={vKeys} value={vItems}>{vItems}</option>)
					})}
				</CFormSelect>
				</CInputGroup>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol md="5" lg="4" className="fw-bolder">Jenis Kelamin</CCol>
			<CCol md="7" lg="5">
				<CFormSelect size="sm"
					value={uFormfieldObj.sex_pasien}
					onChange={(e)=>{uFormfieldObj.sex_pasien = (e.target.value);setEfekview(!uEfekview);}}
					className=""
					id="inpsex">
				<option value="L">Laki-laki [L]</option>
				<option value="P">Perempuan [P]</option>
				</CFormSelect>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol md="5" lg="4" className="fw-bolder">Telp/HP</CCol>
			<CCol md="7" lg="5">
				<CFormInput size="sm"
					className="text-uppercase"
					maxLength={35}
					value={uFormfieldObj.telpon_pasien||""}
					onChange={(e)=>{uFormfieldObj.telpon_pasien = (e.target.value);setEfekview(!uEfekview);}}
					autoComplete="off"
					id="inptelphp"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol md="5" lg="4" className="fw-bolder">Alamat</CCol>
			<CCol md="7" lg="8">
				<CFormTextarea size="sm"
					rows={3}
					maxLength={255}
					value={UFunc.br2nl(uFormfieldObj.alamat_pasien)}
					onChange={(e)=>{uFormfieldObj.alamat_pasien = UFunc.nl2br(e.target.value);setEfekview(!uEfekview);}}
					autoComplete="off"
					id="inpalamat"/>
				<div className="classpetunjuk">&#8627; {uBahasaObj.petunjuk_textarea||"Ganti Baris Tekan [SHIFT]+[ENTER]"}</div>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol md="5" lg="4">Asuransi</CCol>
			<CCol md="7" lg="5">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					className="text-capitalize"
					placeholder="--Masukkan Keyword Untuk Memilih.."
					maxLength={100}
					value={uFormfieldObj.asuransi_caption}
					onChange={(e)=>{uFormfieldObj.asuransi_caption = (e.target.value);setEfekview(!uEfekview);}}
					onKeyDown={(e)=>hdlKDownasuransi(e)}
					autoComplete="off"
					id="inpasuransi"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikasuransilookup()}
					id="btnPekerjaanlookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			<CCol md="5" lg="4" className="d-none d-md-block"/>
			<CCol md="7" lg="8">
				<div className="classpetunjuk">
				&#8627; Kosongi Jika Asuransi UMUM (TIDAK ADA Asuransi)
				</div>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol md="5" lg="4">Pekerjaan</CCol>
			<CCol md="7" lg="5">
				<CInputGroup size="sm">
				<CFormInput size="sm"
					className="text-capitalize"
					placeholder="--Masukkan Keyword Untuk Memilih.."
					maxLength={100}
					value={uFormfieldObj.pekerjaan_caption}
					onChange={(e)=>{uFormfieldObj.pekerjaan_caption = (e.target.value);setEfekview(!uEfekview);}}
					onKeyDown={(e)=>hdlKDownpekerjaan(e)}
					autoComplete="off"
					id="inppekerjaan"/>
				{((uFormfieldObj.pekerjaan_caption||"").trim()!=="" && parseInt(uFormfieldObj.pekerjaan_id) <= 0 ) && (
					<CInputGroupText className="py-0 px-1 border bg-transparent">
					<CImage src={pjson.homepage+"api/images/icon_new.png"} height={30} className="ms-1 classblinking"/>
					</CInputGroupText>
				)}
				{(isMobile) && (
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikpekerjaanlookup()}
					id="btnPekerjaanlookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				)}
				</CInputGroup>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol md="5" lg="4">Alergi</CCol>
			<CCol md="7" lg="8">
				<CFormInput size="sm"
					className="text-capitalize"
					maxLength={100}
					value={uFormfieldObj.alergi}
					onChange={(e)=>{uFormfieldObj.alergi = (e.target.value);setEfekview(!uEfekview);}}
					autoComplete="off"
					id="inpalergi"/>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol md="5" lg="4">
				Kondisi Khusus
				<span className="d-lg-block ms-1 ms-lg-0 px-0 classpetunjuk">
				<small>(Yang Perlu Diperhatikan)</small>
				</span>
			</CCol>
			<CCol md="7" lg="8">
				<CFormInput size="sm"
					className="text-capitalize"
					maxLength={60}
					value={uFormfieldObj.keterangan}
					onChange={(e)=>{uFormfieldObj.keterangan = (e.target.value);setEfekview(!uEfekview);}}
					onKeyDown={(e)=>{
						if(!uShowbutton)
							UFunc.hdlKeyDownInput(e,"btnDialogSimpan")
					}}
					autoComplete="off"
					id="inpketerangan"/>
			</CCol>
			</CRow>
		</>
		)//->
	}

	const apiLoadinit = () => {
		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_cardform";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			if(output_string.tampil) {
				setSessionaktif(true);
				//console.log("(MyCardformpasien - apiLoadinit) output_string.dataobject : "+output_string.dataobject);
				const vTmpArr = JSON.parse(output_string.dataobject || "[]");
				const vPekerjaanArr = vTmpArr.map(vItems => {
					return {id:vItems.id,caption:vItems.pekerjaan_caption}
				})	
				setPekerjaanArr(vPekerjaanArr);

				const vAsuransiArr = (JSON.parse(output_string.dataasuransi || "[]")).
					map(vItems => ({id:vItems.id,caption:vItems.asuransi_caption}));
				setAsuransiArr(vAsuransiArr);
			} else if(output_string.info) {
				console.log("(MyCardformpasien - apiLoadinit) output_string.info : "+output_string.info);
			} else if(output_string.errors) {
				console.log("(MyCardformpasien - apiLoadinit) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			console.log("(MyCardformpasien - apiLoadinit) catch-error : "+error);
		});
	}
	const apiLoadpasien = (_IDPASIEN) => {
		setApimsg();
		setPasienloadObj({});
		setDlgloading(true);

		const vDATAS	= JSON.stringify({
			send_pasienid : _IDPASIEN,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setDlgloading(false);
			if(output_string.tampil) {
				const vTmpObj	= JSON.parse(output_string.datapasien||"{}");
				if(JSON.stringify(vTmpObj||"{}")==="{}") {
					setApimsg(uBahasaObj.caption_dataempty||"Maaf, Data Tidak Ditemukan !");
					return;
				}
				setPasienloadObj(vTmpObj);
			} else if(output_string.info) {
				setApimsg(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyCardformpasien - apiLoadpasien) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setApimsg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setDlgloading(false);
			console.log("(MyCardformpasien - apiLoadpasien) catch-error : "+error);
			setApimsg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		const vPasienID = parseInt(pasienID)||0;
		uDispatch({type: "set", gInitprosespasien: false});

		let vTanggallahir 	= uFormfieldObj.thn+"-"+uFormfieldObj.bln+"-"+uFormfieldObj.tgl;
		const visValiddate = parseInt(uFormfieldObj.thn) > 0 
			&& (parseInt(uFormfieldObj.bln) > 0 && parseInt(uFormfieldObj.bln) <= 12)
			&& (parseInt(uFormfieldObj.tgl) > 0 && parseInt(uFormfieldObj.tgl) <= 31);
		if(!visValiddate) vTanggallahir = "";

		//const vTanggallahirDT 	= new Date(vTanggallahir)
		if(!UFunc.isDateValid(vTanggallahir)) {
			vTanggallahir = "";
		}
		uFormfieldObj.tgl_lahir = vTanggallahir;

		const vRegisterihsBl = 
			((uPasienloadObj.nik||"").trim() !== (uFormfieldObj.nik||"").trim() &&
				(uFormfieldObj.nik||"").trim() !== "" )
			|| ((uFormfieldObj.nik||"").trim() !== "" && (uFormfieldObj.ihs_number||"")==="" )

		const vElbutton = document.getElementById("btnSimpan");
		const vElDlgbutton = document.getElementById("btnDialogSimpan");
		vElDlgbutton && (vElDlgbutton.disabled = true);
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);

			initFormawal();
			showToast(uBahasaObj.pesan_sukses,"SUKSES")
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : vPasienID,
			send_datajson: JSON.stringify(uFormfieldObj),
			/*send_namalengkap : uFormfieldObj.nama_lengkap_pasien,
			send_panggilan : uFormfieldObj.nama_panggilan_pasien,
			send_sex : uFormfieldObj.sex_pasien,
			send_tgllahir : vTanggallahir,
			send_telphp : uFormfieldObj.telpon_pasien,
			send_alamat : uFormfieldObj.alamat_pasien,
			send_pekerjaan : uFormfieldObj.pekerjaan_caption,
			send_asuransiid : uFormfieldObj.asuransi_id,
			send_alergi : uFormfieldObj.alergi,
			send_keterangan : uFormfieldObj.keterangan,
			send_norm : uFormfieldObj.no_rm,
			send_tempatlahir : uFormfieldObj.tempat_lahir,//-*/
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElDlgbutton && (vElDlgbutton.disabled = false);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				if(vPasienID <= 0) {
					const vPasienIDresponse = output_string.tabelid || "0";
					if(vRegisterihsBl) {
						apiRegisterihs(vPasienIDresponse);
						return;
					}

					initFormawal();
					if(!isMobile) {
						const vElfocus = document.getElementById("inpnamalengkap");
						vElfocus && vElfocus.focus();
					}

					showToast(uBahasaObj.pesan_sukses,"SUKSES");
				} else {
					if(vRegisterihsBl) {
						apiRegisterihs(vPasienID);
						return;
					}

					const vTmpObj = {
						pasien_caption : uFormfieldObj.nama_lengkap_pasien,
						panggilan : uFormfieldObj.nama_panggilan_pasien,
						sex : uFormfieldObj.sex_pasien,
						tgl_lahir : vTanggallahir,
						umur : output_string.umur || "",
						telp_hp : uFormfieldObj.telpon_pasien,
						alamat : (uFormfieldObj.alamat_pasien),
						pekerjaan : uFormfieldObj.pekerjaan_caption,
						asuransi : uFormfieldObj.asuransi_caption,
						alergi : uFormfieldObj.alergi,
						keterangan : uFormfieldObj.keterangan,
						nik : uFormfieldObj.nik,
						ihs_number : uFormfieldObj.ihs_number,
					}
					uDispatch({type: "set", gInitprosespasien: true});
					uDispatch({type: "set", gPasiendetilObj: vTmpObj});
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyCardformpasien - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElDlgbutton && (vElDlgbutton.disabled = false);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(MyCardformpasien - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiRegisterihs = (_IDTABEL) => {
		const vAccesstoken 		= uTokensatusehatObj.access_token;
		
 		if(!vAccesstoken) {
 			loadToken(apiRegisterihs); return;
 		}
		const vLimitedsec		= parseInt(uTokensatusehatObj.expires_in || "0");
		const vTimestampnow 	= new Date();
		const vTimetoken		= new Date(parseInt(uTokensatusehatObj.issued_at || "0"));
		const vSelisih			= UFunc.Datetimeselisih(vTimetoken,vTimestampnow).dtk;

		if(vSelisih > vLimitedsec) {
 			loadToken(apiRegisterihs); return;
		}

		_IDTABEL 		= parseInt(_IDTABEL)||0;
		const vPasienID = parseInt(pasienID)||0;
		uDispatch({type: "set", gInitprosespasien: false});

		let vTanggallahir 	= uFormfieldObj.thn+"-"+uFormfieldObj.bln+"-"+uFormfieldObj.tgl;
		const visValiddate = parseInt(uFormfieldObj.thn) > 0 
			&& (parseInt(uFormfieldObj.bln) > 0 && parseInt(uFormfieldObj.bln) <= 12)
			&& (parseInt(uFormfieldObj.tgl) > 0 && parseInt(uFormfieldObj.tgl) <= 31);
		if(!visValiddate) vTanggallahir = "";

		//const vTanggallahirDT 	= new Date(vTanggallahir)
		if(!UFunc.isDateValid(vTanggallahir)) {
			vTanggallahir = "";
		}

		const vElbutton = document.getElementById("btnSimpan");
		const vElDlgbutton = document.getElementById("btnDialogSimpan");
		vElDlgbutton && (vElDlgbutton.disabled = true);
		vElbutton && (vElbutton.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);

			initFormawal();
			showToast(uBahasaObj.pesan_sukses,"SUKSES")
		},2000); return;//--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_accesstoken : vAccesstoken,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_registerihs";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElDlgbutton && (vElDlgbutton.disabled = false);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);

			const vIHSnumber	= output_string.ihs_number || "";
			const vIHSmessage 	= output_string.ihs_message || "";
			if(vPasienID > 0) {
				const vTmpObj = {
					pasien_caption : uFormfieldObj.nama_lengkap_pasien,
					panggilan : uFormfieldObj.nama_panggilan_pasien,
					sex : uFormfieldObj.sex_pasien,
					tgl_lahir : vTanggallahir,
					umur : output_string.umur || "",
					telp_hp : uFormfieldObj.telpon_pasien,
					alamat : UFunc.nl2br(uFormfieldObj.alamat_pasien),
					pekerjaan : uFormfieldObj.pekerjaan_caption,
					asuransi : uFormfieldObj.asuransi_caption,
					alergi : uFormfieldObj.alergi,
					keterangan : uFormfieldObj.keterangan,
					nik : uFormfieldObj.nik,
					ihs_number : vIHSnumber,
				}

				uDispatch({type: "set", gInitprosespasien: true});
				uDispatch({type: "set", gPasiendetilObj: vTmpObj});
			} else {
				initFormawal();
				if(!isMobile) {
					const vElfocus = document.getElementById("inpnamalengkap");
					vElfocus && vElfocus.focus();
				}
			}

			if(output_string.sukses) {
				if(vPasienID <= 0) {
					let vOutputmsg = uBahasaObj.pesan_sukses || "SUKSES";
					if(vIHSmessage !== "")
						vOutputmsg	+= "<br/><br/>SATUSEHAT: <b>"+vIHSmessage+"</b>";

					showToast(vOutputmsg,"SUKSES");
				} else {
					if(vIHSmessage !== "")
						showToast("SATUSEHAT: <b>"+vIHSmessage+"</b>","SUKSES");
				}
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyCardformpasien - apiRegisterihs) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElDlgbutton && (vElDlgbutton.disabled = false);
			vElbutton && (vElbutton.disabled = false);
			setLoading(false);
			console.log("(MyCardformpasien - apiRegisterihs) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}

	React.useEffect(()=>{
		apiLoadinit()
		
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile) {
				const vElfocus = document.getElementById("inpnamalengkap");
				vElfocus && vElfocus.focus();
			}
		},350);

		return () => {
			setPasienloadObj({});
			setPekerjaanArr([]);
			setAsuransiArr([]);
			initFormawal();
			//setSettingdefault({})
		}
	},[])
	React.useEffect(()=>{
		const vBatasyear= parseInt(uSystemsetting.start_tahunlahir || "1950");
		const vYearnow	= (new Date).getFullYear();
		const vTmptahun	= [];
		for(var vIdx=vYearnow;vIdx >= vBatasyear; vIdx--)
			vTmptahun.push(vIdx);
		setThnlahirArr(vTmptahun);
	},[uSystemsetting])
	React.useEffect(()=>{
		//console.log("(MyCardformpasien [pasienID]) pasienID 1 = "+pasienID)
		if((parseInt(pasienID)||0) <= 0) { initFormawal(); return}

		//console.log("(MyCardformpasien [pasienID]) pasienID 2 = "+pasienID)
		apiLoadpasien(pasienID);
	},[pasienID])
	React.useEffect(()=>{
		if(uFormfieldObj.sex_pasien===undefined)
			uFormfieldObj.sex_pasien = "L";
		if(uFormfieldObj.tgl===undefined)
			uFormfieldObj.tgl = "0";
		if(uFormfieldObj.bln===undefined)
			uFormfieldObj.bln = "0";
		if(uFormfieldObj.thn===undefined)
			uFormfieldObj.thn = "0";
	},[uFormfieldObj,
	uFormfieldObj.tgl,
	uFormfieldObj.bln,
	uFormfieldObj.thn,
	uFormfieldObj.sex_pasien
	])
	React.useEffect(()=>{
		if((uFormfieldObj.nik||"").toUpperCase().trim() !== (uFormfieldObj.nik_before||"").toUpperCase().trim())
			uFormfieldObj.ihs_number = "";
		else
			uFormfieldObj.ihs_number = uPasienloadObj.ihs_number||"";
	},[uFormfieldObj,
	uFormfieldObj.nik
	])
	React.useEffect(()=>{
		//console.log("(MyCardformpasien [uPasienloadObj]) LEWAT 1")
		if(JSON.stringify(uPasienloadObj)==="{}") return;

		//console.log("(MyCardformpasien [uPasienloadObj]) LEWAT 2")

		const vTanggallahir = uPasienloadObj.tgl_lahir||"";
		let vTgl = "0";
		let vBln = "0";
		let vThn = "0";
		if(vTanggallahir !== "") {
			const vTanggallahirDT = UFunc.toDate(vTanggallahir);

			vTgl = UFunc.leadZero(vTanggallahirDT.getDate());
			vBln = UFunc.leadZero(vTanggallahirDT.getMonth()+1);
			vThn = UFunc.leadZero(vTanggallahirDT.getFullYear());
		}

		uFormfieldObj.nama_lengkap_pasien = (uPasienloadObj.pasien_caption || "");
		uFormfieldObj.no_rm 	= (uPasienloadObj.no_rm || "1");
		uFormfieldObj.nama_panggilan_pasien = (uPasienloadObj.panggilan || "");
		uFormfieldObj.sex_pasien = (uPasienloadObj.sex || "L");
		uFormfieldObj.tgl 		= (vTgl); uFormfieldObj.bln = (vBln); uFormfieldObj.thn = (vThn);

		uFormfieldObj.tempat_lahir 	= (uPasienloadObj.tempat_lahir || "");
		uFormfieldObj.telpon_pasien = (uPasienloadObj.telp_hp || "");
		uFormfieldObj.alamat_pasien = (UFunc.br2nl(uPasienloadObj.alamat || ""));
		uFormfieldObj.alergi 		= (uPasienloadObj.alergi || "");
		uFormfieldObj.keterangan 	= (uPasienloadObj.keterangan || "");
		uFormfieldObj.nik 			= (uPasienloadObj.nik || "");
		uFormfieldObj.nik_before 	= (uPasienloadObj.nik || "");
		uFormfieldObj.ihs_number 	= (uPasienloadObj.ihs_number || "");

		const vPekerjaancaption 	= uPasienloadObj.pekerjaan_caption || "";
		uFormfieldObj.pekerjaan_caption = (vPekerjaancaption);
		const vPekerjaanidx 		= UFunc.getObjectIndeks(uPekerjaanArr,vPekerjaancaption,"caption");
		uFormfieldObj.pekerjaan_id 	= ((uPekerjaanArr[vPekerjaanidx]||{}).id);

		const vAsuransicaption 		= uPasienloadObj.asuransi_caption || "";
		uFormfieldObj.asuransi_caption = (vAsuransicaption);
		const vAsuransiidx 			= UFunc.getObjectIndeks(uAsuransiArr,vAsuransicaption,"caption");
		uFormfieldObj.asuransi_id 	= ((uAsuransiArr[vAsuransiidx]||{}).id);

		if(!isMobile) {
			const vElfocus = document.getElementById("inpnamalengkap");
			vElfocus && vElfocus.focus();
		}
	},[uPasienloadObj])
	React.useEffect(()=>{
		if((simpanKlik||false)===false) return;
		hdlKlikSimpan()
	},[simpanKlik])
	React.useEffect(()=>{ setShowbutton(showButton); },[showButton])
	React.useEffect(()=>{
		if(!uISDlglookupshow)
			{ setDlglookupObj({}); return }
	},[uISDlglookupshow])

	//console.log("(MyCardformpasien) pasienID = "+pasienID)
	return (
		<>
		<CForm>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<strong>{parseInt(pasienID||0)<=0
			?(uBahasaObj.caption_inputpasienbaru||"Input Pasien Baru")
			:"Edit Data Pasien"}</strong>
		<CLink onClick={()=>hdlKlikReset()}
			className="classikon classikonreset classcursorpointer"/>
		</CCardHeader>

		<CCardBody className={(uShowbutton)?"px-lg-5":""}>{contentMain()}</CCardBody>

		{(uShowbutton===true) && (
		<CCardFooter className={"text-center"}>
			<MyButton
				onClick={()=>hdlKlikSimpan()}
				iconname="cilCheckAlt"
				id="btnSimpan">S I M P A N</MyButton>
		</CCardFooter>
		)}
		</CCard>
		</CForm>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.headers||"Lookup Data"}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr || []} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>
		</>
	)//>
}
MyCardformpasien.defaultProps = {
	pasienID: 0,            
	showButton: true,     
	simpanKlik: false,
};

MyCardformpasien.propTypes = {
	pasienID: PropTypes.number,            
	showButton: PropTypes.bool,     
	simpanKlik: PropTypes.bool,
}

export default MyCardformpasien;